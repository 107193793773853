<div class="milestone-progress-bar-container">
  <p class="body2--bold white">{{ viewState.tierName }}</p>
  <div class="middle-container" [ngClass]="{ 'first-tier': viewState.tierIndex === 0 }">
    <mat-progress-bar
      class="milestone-progress-bar"
      mode="determinate"
      value="{{ viewState.progress }}"
      *ngIf="viewState.tierIndex !== 0"
    >
    </mat-progress-bar>
    <img
      class="milestone-icon"
      src="{{ viewState.tierIcon }}"
      [ngClass]="{ dimmed: viewState.isDimmed }"
    />
  </div>
  <p class="body2--regular white bottom-text" *ngIf="viewState.tierIndex !== 0">
    {{ viewState.targetOrder }}{{ 'LOYALTY_PROGRAM.CONFIRMED_ORDER' | translate }}
  </p>
</div>
