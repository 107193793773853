import { DatePipe, NgFor } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TierModel } from 'src/app/core/domain/loyalty-program/loyalty-program.model';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { monthNameDateFormat } from 'src/app/presentation/shared/constants';
import { TiersProgressBarPresenter } from './presentation/tier-progress-bar.presenter';
import { TiersProgressBarSideEffect } from './presentation/tier-progress-bar.side-effects';
import { TiersProgressBarViewEvent } from './presentation/tier-progress-bar.view-events';
import { TiersProgressBarViewState } from './presentation/tier-progress-bar.view-state';
import { TierProgressMilestoneComponent } from './tiering-progress-milestone/tier-progress-milestone.component';

@Component({
  selector: 'app-tiering-progress-bar',
  standalone: true,
  imports: [TierProgressMilestoneComponent, NgFor, TranslateModule, DatePipe],
  templateUrl: './tiering-progress-bar.component.html',
  styleUrls: ['./tiering-progress-bar.component.scss'],
  providers: [TiersProgressBarPresenter],
})
export class TieringProgressBarComponent extends BaseComponent<
  TiersProgressBarPresenter,
  TiersProgressBarViewState,
  TiersProgressBarViewEvent,
  TiersProgressBarSideEffect
> {
  tiersData: TierModel[];

  public dateFormat = monthNameDateFormat;

  public presenter: TiersProgressBarPresenter = inject(TiersProgressBarPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }
}
