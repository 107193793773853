<p class="heading2--bold title">
  {{ 'TIERING_SYSTEM_LANDING.TITLE' | translate }}
  <bdi class="title caption1--regular">
    ( {{ viewState.progress.startDate | date : dateFormat }} -
    {{ viewState.progress.endDate | date : dateFormat }} )</bdi
  >
</p>
<div class="container">
  <div *ngFor="let tier of viewState.tiers">
    <app-tiering-progress-milestone
      [tier]="tier"
      [selectedTierIndex]="viewState.progress.currentLoyaltyTierIndex"
      [currentPlacedOrder]="viewState.progress.achievedOrders"
    ></app-tiering-progress-milestone>
  </div>
</div>
