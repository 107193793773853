import { NgClass, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MilestoneTier } from 'src/app/core/domain/loyalty-program/loyalty-program.model';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { TierProgressMilestonePresenter } from './presentation/tier-progress-milestone.presenter';
import { TierProgressMilestoneSideEffect } from './presentation/tier-progress-milestone.side-effects';
import { TierProgressMilestoneViewEvent } from './presentation/tier-progress-milestone.view-events';
import { TierProgressMilestoneViewState } from './presentation/tier-progress-milestone.view-state';

@Component({
  selector: 'app-tiering-progress-milestone',
  templateUrl: './tier-progress-milestone.component.html',
  styleUrls: ['./tier-progress-milestone.component.scss'],
  standalone: true,
  imports: [MatProgressBarModule, TranslateModule, NgIf, NgClass],
  providers: [TierProgressMilestonePresenter],
})
export class TierProgressMilestoneComponent extends BaseComponent<
  TierProgressMilestonePresenter,
  TierProgressMilestoneViewState,
  TierProgressMilestoneViewEvent,
  TierProgressMilestoneSideEffect
> {
  @Input() tier: MilestoneTier;

  @Input() selectedTierIndex: number;

  @Input() currentPlacedOrder: number;

  public presenter: TierProgressMilestonePresenter = inject(TierProgressMilestonePresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
      tier: this.tier,
      selectedTierIndex: this.selectedTierIndex,
      currentPlacedOrder: this.currentPlacedOrder,
    });
  }
}
