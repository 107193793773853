// tier-progress-bar Presenter

import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import {
  MilestoneTier,
  TierModel,
} from 'src/app/core/domain/loyalty-program/loyalty-program.model';
import { GetLoyaltyProgressUseCase } from 'src/app/core/usecases/loyalty-program/get-progress.usecase';
import { GetLoyaltyTiersDataUseCase } from 'src/app/core/usecases/loyalty-program/get-tiers-data.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { TiersProgressBarSideEffect } from './tier-progress-bar.side-effects';
import { TiersProgressBarViewEvent } from './tier-progress-bar.view-events';
import { TiersProgressBarViewState } from './tier-progress-bar.view-state';

@Injectable({ providedIn: 'root' })
export class TiersProgressBarPresenter extends BasePresenter<
  TiersProgressBarViewState,
  TiersProgressBarViewEvent,
  TiersProgressBarSideEffect
> {
  constructor(
    private _getLoyaltyTiersDataUseCase: GetLoyaltyTiersDataUseCase,
    private _getLoyaltyProgressUseCase: GetLoyaltyProgressUseCase,
  ) {
    super();
  }

  protected defaultViewState(): TiersProgressBarViewState {
    return {
      tiers: [],
      progress: {
        startDate: new Date(),
        endDate: new Date(),
        currentLoyaltyTierIndex: 0,
        actualLoyaltyTierIndex: 0,
        achievedOrders: 0,
        inProgressOrders: 0,
      },
    };
  }

  protected onViewEvent(event: TiersProgressBarViewEvent): void {
    switch (event.type) {
      case 'Init': {
        const loyaltyTiersData$ = this._getLoyaltyTiersDataUseCase.execute();

        const loyaltyProgress$ = this._getLoyaltyProgressUseCase.execute();

        this.add(
          combineLatest([loyaltyTiersData$, loyaltyProgress$]).subscribe(
            ([loyaltyTiersData, loyaltyProgress]) => {
              this.updateViewState({
                tiers: this.mapDomainModelToPresentationalModel(loyaltyTiersData),
                progress: loyaltyProgress,
              });
            },
          ),
        );
      }
    }
  }

  mapDomainModelToPresentationalModel(tiers: TierModel[]): MilestoneTier[] {
    return tiers.map((tier, index) => {
      return {
        tierName: tier.displayName,
        tierIcon: tier.icon,
        index: tier.index,
        currentTargetOrdersNumber: tier.targetOrders,
        targetOrderStatus: tier.targetOrderStatus,
        previousTargetOrdersNumber: index > 1 ? tiers[index - 1].targetOrders : 0,
      };
    });
  }
}
