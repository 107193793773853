// tier-progress-milestone Presenter

import { Injectable } from '@angular/core';
import { MilestoneTier } from 'src/app/core/domain/loyalty-program/loyalty-program.model';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { TierProgressMilestoneSideEffect } from './tier-progress-milestone.side-effects';
import { TierProgressMilestoneViewEvent } from './tier-progress-milestone.view-events';
import { TierProgressMilestoneViewState } from './tier-progress-milestone.view-state';

@Injectable({ providedIn: 'root' })
export class TierProgressMilestonePresenter extends BasePresenter<
  TierProgressMilestoneViewState,
  TierProgressMilestoneViewEvent,
  TierProgressMilestoneSideEffect
> {
  constructor(private _siteTranslateService: SiteTranslateService) {
    super();
  }

  protected defaultViewState(): TierProgressMilestoneViewState {
    return {
      tierName: '',
      tierIcon: '',
      tierIndex: 0,
      targetOrder: 0,
      progress: 0,
      isDimmed: true,
    };
  }

  protected onViewEvent(event: TierProgressMilestoneViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.updateViewState({
          ...this.viewState,
          tierName:
            this._siteTranslateService.getCurrentLanguage() === 'en'
              ? event.tier.tierName.en!
              : event.tier.tierName.ar,
          tierIcon: event.tier.tierIcon,
          tierIndex: event.tier.index,
          targetOrder: event.tier.currentTargetOrdersNumber,
          progress: this.getProgress(event.selectedTierIndex, event.currentPlacedOrder, event.tier),
          isDimmed: event.tier.index > event.selectedTierIndex,
        });
        this.add(
          this._siteTranslateService.onLanguageChange().subscribe((lang) => {
            this.updateViewState({
              ...this.viewState,
              tierName: lang === 'en' ? event.tier.tierName.en! : event.tier.tierName.ar,
            });
          }),
        );
      }
    }
  }

  getProgress(selectedIndex: number, placedOrders: number, tier: MilestoneTier): number {
    if (selectedIndex >= tier.index) return 100;
    if (placedOrders < tier.previousTargetOrdersNumber) return 0;
    return (
      ((placedOrders - tier.previousTargetOrdersNumber) /
        (tier.currentTargetOrdersNumber - tier.previousTargetOrdersNumber)) *
      100
    );
  }
}
